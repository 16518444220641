import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import OnLineErrors from "./OnLineErrors";
import axios from "axios";
import { apiUrl } from "../config";
import { Spinner } from "react-activity";
import "react-activity/dist/react-activity.css";
import Header from './Header';
class Enquiry extends Component {
  state = {
    data: {
      username: "",
      password: ""
    },
    errors: {},
    loading: false,
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  handleClear = () => {
    this.setState({ data: { ...this.state.data, username: "", password: "" } });
  };

  onSubmit = () => {
    const { data } = this.state;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      //alert("Thank You, Registered successfully");
      console.log('---------',this.state.data)
      if(data.username === 'admin' &&  data.password === 'kapvgmc'){
        localStorage.setItem('auth',JSON.stringify('ghdcvdjhscvdscgvdshcxfg'))
        this.props.history.push('/dashboard');
      } else {
        alert('UserName Or Password is Incorrect')
      }
      
      //this.handleClear();
    }
  };

  handleName =()=>{}
  validate = (data) => {
    const errors = {};
    if (!data.username) {
      errors.username = "Username Can't Empty";
    }

    if (!data.password) {
      errors.password = "Password Can't Empty";
    }

    return errors;
  };

  render() {
    const { data, errors, loading } = this.state;
    return (
      <div className="container-fluid">
          <Header onName={this.handleName}/>
        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <h2 style={{ marginTop: "3rem", textAlign: "center" }}>
              {" "}
              Login Form
            </h2>
          </div>
          <div className="col-md-4"></div>
        </div>

        <div className="row" style={{ marginTop: "2rem" }}>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <div
              style={{
                boxShadow: "0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)",
                padding: "1rem",
              }}
            >
              <TextField
                placeholder="Enter the Username"
                label="Username"
                onChange={this.onChange}
                value={data.name}
                name="username"
                autoFocus={true}
                required
                margin="normal"
                fullWidth={true}
              />
              {errors.username && <OnLineErrors text={errors.username} />}

              <div
                style={{
                  marginTop: "2rem",
                }}
              >
                <TextField
                  id="standard-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  fullWidth={true}
                  name="password"
                  onChange={this.onChange}
                />
                {errors.password && <OnLineErrors text={errors.password} />}
              </div>

              <div style={{ marginTop: "3rem" }}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={this.onSubmit}
                >
                  Login
                </Button>
              </div>
              <div style={{ marginTop: "3rem" }}>
                {this.state.loading ? (
                  <div style={{ marginLeft: "40%" }}>
                    <Spinner color="red" size={20} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
      </div>
    );
  }
}

export default Enquiry;
