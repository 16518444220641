import React from "react";
import logo from '../img/logo.jpeg';
const Header = (props) => {
  return (
    <div className="row">
      <div
        className="col-md-12"
        style={{ backgroundColor: "#003a6a", height: "8rem" }}
      >
        <div className="row">
          <div className="col-sm-12 col-md-3">
              <img onClick={()=>{props.onName()}} src={logo} style={{height:'5rem',textAlign:'center', marginTop: "1.5rem"}} />
          </div>
          <div className="col-sm-12 col-md-9">
            <h3
              style={{ color: "#fff", textAlign: "centerLeft", marginTop: "2rem" }}
            >
             ALUMNI ASSOCIATION OF KAPVGOVT.MEDICAL COLLEGE
            </h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
