import React, { Component } from 'react';
import axios from "axios";
import { apiUrl } from "../config"

class Faculty extends Component {
    state={
        tid:"Tamil",
        mydata: [],
    }

    componentDidMount() {   
       this.handleData();
     }

     handleData = ()=>{
        const id=this.state.tid;
        axios.get(`${apiUrl}/staff/${id}`).then((res) => {
            console.log("---- RES ----", res.data);
            this.setState({ mydata: res.data.result });
           });
     }
 
    
    render() {
        console.log('*****',this.state.mydata)
        return (
            <div className="container-fluid">
             <div className="row">
                 <div className="col-md-4">
                     <div style={{marginTop:'2rem',padding:'2rem'}}>
                        <button className="btn btn-outline-success btn-block" onClick={()=>this.setState({tid:'Tamil'},()=>{this.handleData()})}>Tamil</button>
                        <button className="btn btn-outline-warning btn-block" onClick={()=>this.setState({tid:'English'},()=>{this.handleData()})}>English</button>
                        <button className="btn btn-outline-info btn-block" onClick={()=>this.setState({tid:'Computer Science'},()=>{this.handleData()})}>Computer Science</button>
                        <button className="btn btn-outline-primary btn-block" onClick={()=>this.setState({tid:'Mathematics'},()=>{this.handleData()})}>Mathematics</button>
                        <button className="btn btn-outline-danger btn-block" onClick={()=>this.setState({tid:'Physics'},()=>{this.handleData()})}> Physics</button>                                      
                     </div>
                    
                 </div>

                 <div className="col-md-5" style={{backgroundColor:'#fff'}}>
                     <h3 style={{marginTop:'2rem',textAlign:'center',fontStyle:'italic'}}>Department Of {this.state.tid}</h3>
                    
                   {this.state.mydata.length > 0 ? ( 
                   this.state.mydata.map((e,index) => ( 
                      
                        
                         <div key={index} className="row" style={{backgroundColor: "rgb(255, 255, 255)", borderTop: '2px solid #2980b9', boxShadow: '0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)',marginTop:'1.5rem',paddingTop:'1rem' }}
                         >                            
                            <div className="col-12 col-md-4" style={{textAlign:'center',paddingTop:'1.5rem',borderRight: '1px solid #2980b9'}} >  
                            <img src={`${apiUrl}/uploads/${e.photo}`} style={{height:'5rem'}} />                                                
                            </div> 
                            <div className="col-12 col-md-8" > 
                            <div style={{padding:'1rem'}}>
                                    <p>Name: {e.name}</p>                                  
                                    <p>Designation: {e.designation}</p>   
                                    <p>Department : {e.department}</p> 
                            </div>                                                                   
                            </div>                        
                         </div>
 
//

                    ))
                    ):(
                        <h5>No Data</h5>
                      )}
                 </div>

                  <div className="col-md-3">
                  </div>                
             </div>
            </div>
        );
    }
}

export default Faculty;