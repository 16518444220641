import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { BrowserRouter }  from 'react-router-dom'

import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";

ReactDOM.render(
  <BrowserRouter>
    <App />
    </BrowserRouter>,    
  document.getElementById('root')
);



