import React, { Component } from 'react';
import { TextField, Button } from "@material-ui/core";
import { Box } from '@material-ui/core'
import Header from './Header';

class DashBoard extends Component {  

    handleName =()=>{}
    render() {
          return (
            <div className="container-fluid">
                <Header onName={this.handleName}/>
                <div className="row" style={{marginTop:'3rem'}}>
                          <div className=" col-md-5">
                          </div>
                          <div className="col-sm-6 col-md-5">
                          <h2> DASHBOARD</h2>   
                          </div>
                          <div className="col-sm-6 col-md-2">
                                <Button
                                    type="submit"
                                    
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>{localStorage.clear()
                                      this.props.history.push('/')}}>
                                    Log Out 
                                </Button>  
                          </div>
                      </div>

                
                <div className="row" style={{marginTop:'5rem',padding:'2rem'}}>
                   
                    <div className="col-md-4">
                    {/* <div  style={{boxShadow: '0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)',padding:'1rem'}}>                       
                        <Box 
                            onClick={()=>{this.props.history.push('/reg')}}
                            display="flex" 
                            width={'100%'} height={80} 
                            bgcolor="pink"
                            alignItems="center"
                            justifyContent="center"
                        >
                            1. Faculty Maintenance
                        </Box>
                            </div> */}
                    </div>
                    <div className="col-md-4">
                        <div  style={{boxShadow: '0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)',padding:'1rem'}}>                       
                        <Box 
                            onClick={()=>{this.props.history.push('/appformdash')}}
                            display="flex" 
                            type="button"
                            width={'100%'} height={80} 
                            bgcolor="lightgreen"
                            alignItems="center"
                            justifyContent="center"
                        >
                            Alumni Member Details
                        </Box>
                        </div>
                    </div>
                    <div className="col-md-4">                    
                    </div>
                </div>
               
            </div>
        );
    }
}

export default DashBoard;