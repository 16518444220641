import React from 'react';
import Faculty from './components/Faculty';
import Faculty_Reg from './components/Faculty_Reg';
import Enquiry from './components/Enquiry';
import Login from './components/Login';
import AppForm from './components/AppForm';
import AppForm_Dash from './components/AppForm_Dash';
import DashBoard from './components/DashBoard';
import ProtectedRouter from './Protected';
import { Redirect } from "react-router-dom";
import {Switch, Route } from 'react-router-dom';

function App() {
  return (
   <div>
          <Switch>
            <ProtectedRouter exact path="/dashboard"  component={DashBoard} />
            {/* <Route exact path="/faculty"  component={Faculty} /> */}
            {/* <ProtectedRouter exact path="/reg"  component={Faculty_Reg} /> */}
            {/* <Route exact path="/enquiry"  component={Enquiry} /> */}
            <Route exact path="/"  component={AppForm} />
            <ProtectedRouter exact path="/appformdash"  component={AppForm_Dash} />
            {/* <ProtectedRouter exact path="/reg"  component={Faculty_Reg} /> */}
            <Route exact path="/login"  component={Login} />
           
            <Route render={() => <Redirect to={{ pathname: "/" }} />} />
          </Switch>     
   </div>
  );
}

export default App;
