import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as moment from "moment";
import OnLineErrors from "./OnLineErrors";
import axios from "axios";
import { apiUrl } from "../config";
import Select from "react-select";
import * as EmailValidator from "email-validator";
import RadioComponent from "./Radio";
import Loader from "react-loader-spinner";
import Header from "./Header";
import { Box } from "@material-ui/core";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

class AppForm extends Component {
  state = {
    data: {
      name: "",
      hobby: "",
      spouse: "",
      spouseOccupation: "",
      startDate: "",
      dob: "",
      job: "",
      address_line1: "",
      address_line2: "",
      pincode: "",
      district: "",
      state: "",
      country: "",
      email: "",
      ug_pg: "",
      ug_pg_val: "",
      speciality: "",
      course: "",
      courseval: "",
      message: "",
      file1: "",
      photo: "",
      selectedOption: null,
      selectcourse: [],
      gender: "",
      yoj: "",
      phone: "",
      regno: "",
    },
    file: null,
    mydata: [],
    errors: {},
    loading: false,
    ugcourse: [
      { value: "MBBS", label: "MBBS" },
    ],
    pgcourse: [
      { value: "MD GENERAL MEDICINE", label: "MD GENERAL MEDICINE" },
      { value: "MS GENERAL SURGERY", label: "MS GENERAL SURGERY" },
      { value: "MD ANAESTHESIO LOGY", label: "MD ANAESTHESIO LOGY" },
      { value: "MD PAEDIATRICS", label: "MD PAEDIATRICS" },
      { value: "MS OG", label: "MS OG" },
      { value: "MD BIOCHEMISTRY", label: "MD BIOCHEMISTRY" },
      { value: "M.D PSYCHIATRY", label: "M.D PSYCHIATRY" },
      { value: "M.S ORTHO", label: "M.S ORTHO" },
      { value: "M.D MICROBIOLOGY", label: "M.D MICROBIOLOGY" },
      { value: "M.D ANATOMY", label: "M.D ANATOMY" },
      { value: "M.D PATHOLOGY", label: "M.D PATHOLOGY" },
      { value: "M.S OPHTHALMOLOGY", label: "M.S OPHTHALMOLOGY" },
      { value: "MD FORENSIC MEDICINE", label: "MD FORENSIC MEDICINE" },
      { value: "MS OTORHINOLARYNGOLOGY", label: "MS OTORHINOLARYNGOLOGY" },
    ],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  handleOnChangeDate = (date) => {
    const sdate = moment(date).format("DD-MM-YYYY");
    this.setState({
      data: { ...this.state.data, startDate: date, dob: sdate },
    });
  };

  handleOnChangeNum = (e) => {
    if (e.target.value.length < 11) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          data: { ...this.state.data, [e.target.name]: e.target.value },
        });
      }
    }
  };
  handleOnChangeRegno = (e) => {
    if (e.target.value.length < 15) {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({
          data: { ...this.state.data, [e.target.name]: e.target.value },
        });
      }
    }
  };

  handleAdmissionChange = (admission) => {
    if (admission.value === "UG") {
      this.setState({
        data: {
          ...this.state.data,
          ug_pg: admission,

          selectcourse: this.state.ugcourse,
          course: "", pgcourse: "", courseval: ""
        },
      });
    } else {
      this.setState({
        data: {
          ...this.state.data,
          ug_pg: admission,

          selectcourse: this.state.pgcourse,
          course: "", ugcourse: "", courseval: ""
        },
      });
    }

    //console.log(`Option selected:`, admission);
  };

  handleyojChange = (yoj) => {
    this.setState({
      data: {
        ...this.state.data,
        yoj: yoj.value,
      },
    });
    //console.log(`Option selected:`, yoj);
  };
  handleCourseChange = (course) => {
    this.setState({
      data: { ...this.state.data, courseval: course, course: course.value },
    });
    //console.log(`Option selected:`, course);
  };
  getFormData = (object) => {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  };
  onSubmit = () => {
    const { data } = this.state;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    //console.log(Object.keys(errors).length,"------- length ------", Object.keys(errors).length === 0);
    if (Object.keys(errors).length === 0) {
      //   console.log("------- STATE ------", this.state.data);

      const { regno, name, dob, email, phone, gender, yoj, speciality, job, address_line1,
        address_line2, pincode, district, state, country, course, hobby, spouse, spouseOccupation } = data;
      const statedata = {
        regno, name, dob, email, phone, gender, yoj, speciality, job,
        address_line1, address_line2, pincode, district, state, country, course, hobby, spouse, spouseOccupation
      }

      //console.log('******************',statedata)

      const formData = this.getFormData(statedata);
      // console.log('--- FORMDATA ---',formData);
      // 
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      this.setState({ loading: true });
      axios
        .post(`${apiUrl}/regform/add`, formData, config)
        .then((response) => {
          //console.log("--------- RESPONSE ----------", response);
          this.setState({ loading: false });

          alert(response.data.msg);
        })
        .catch((error) => {
          this.setState({ loading: false });
        });
      //
    }
  };

  handleName = () => { }

  validate = (data) => {
    const errors = {};
    if (!data.name) {
      errors.name = "Name is Required";
    }
    if (!data.dob) {
      errors.dob = "Date Of Birth is Required";
    }
    // if (!data.f_m_name) {
    //   errors.f_m_name = "Father's Name / Mother's name Required";
    // }
    // if (!data.speciality) {
    //   errors.speciality = "Speciality Required";
    // }
    // if (!data.address_line1) {
    //   errors.address_line1 = "Door No, Street Required";
    // }
    // if (!data.address_line2) {
    //   errors.address_line2 = "Area, Village Required";
    // }
    // if (!data.pincode) {
    //   errors.pincode = "Pincode Required";
    // }
    // if (!data.district) {
    //   errors.district = "District Required";
    // }
    // if (!data.state) {
    //   errors.state = "State Required";
    // }
    // if (!data.country) {
    //   errors.country = "Country Required";
    // }
    // if (!data.ug_pg) {
    //   errors.ug_pg = "This field is Required";
    // }
    // if (!data.course) {
    //   errors.course = " Select UG/PG";
    // }
    // if (!data.email) {
    //   errors.email = "Email Required";
    // } else if (EmailValidator.validate(data.email)) {
    // } else {
    //   errors.email = "Invalid email";
    // }
    if (!data.gender) {
      errors.gender = "Gender is Required";
    }
    if (!data.yoj) {
      errors.yoj = "Year of Joining is Required";
    }
    if (!data.phone) {
      errors.phone = "Mobile Number is Required";
    }
    // if (!data.regno) {
    //   errors.regno = "Register Number is Required";
    // }
    // if (data.file1 === "" || data.file1 === undefined) {
    //   errors.file1 = "Image Can't Empty";
    // }

    return errors;
  };

  render() {
    const { data, errors, loading, ugcourse, pgcourse } = this.state;
    const { ug_pg } = this.state.data;
    const admission = [
      { value: "UG", label: "UG" },
      { value: "PG", label: "PG" }
    ];

    const yoj = [
      { value: "1997", label: "1997" }, { value: "1998", label: "1998" }, { value: "1999", label: "1999" },
      { value: "2000", label: "2000" }, { value: "2001", label: "2001" }, { value: "2002", label: "2002" },
      { value: "2003", label: "2003" }, { value: "2004", label: "2004" }, { value: "2005", label: "2005" },
      { value: "2006", label: "2006" }, { value: "2007", label: "2007" }, { value: "2008", label: "2008" },
      { value: "2009", label: "2009" }, { value: "2010", label: "2010" }, { value: "2011", label: "2011" },
      { value: "2012", label: "2012" }, { value: "2013", label: "2013" }, { value: "2014", label: "2014" },
      { value: "2015", label: "2015" }, { value: "2016", label: "2016" }, { value: "2017", label: "2017" },
      { value: "2018", label: "2018" }, { value: "2019", label: "2019" }, { value: "2020", label: "2020" },
      { value: "2021", label: "2021" }, { value: "2022", label: "2022" }, { value: "2023", label: "2023" },
    ];

    return (
      <div className="container-fluid" style={{ backgroundColor: "#f5f5f7" }}>
        <Header onName={this.handleName} />
        <div className="row">
          <div className="col-md-3">
            <Button
              type="submit"

              variant="contained"
              color="default"
              onClick={() => {
                localStorage.clear()
                this.props.history.push('/login')
              }}>
              Admin
            </Button>
          </div>
          <div className="col-md-12">
            <h2
              style={{
                textAlign: "center",
                marginBottom: "1rem",
                marginTop: "3rem",
                color: '#2f3542',
              }}
            >
              Kapvgmc Alumni Registration Form
            </h2>
          </div>
          <div className="col-md-3"></div>
        </div>

        <div className="row">
          <div className="col-md-3"></div>
          <div
            className="col-md-6"
            style={{
              backgroundColor: "#fff",
              padding: "3rem",
              boxShadow: "0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)",
            }}
          >

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Regd.No"
                label="Regd.No"
                onChange={this.handleOnChangeRegno}
                value={data.regno}
                name="regno"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.regno && <OnLineErrors text={errors.regno} />}
            </div>

            <div style={{ marginTop: "1.5rem", width: "20rem" }}>
              <p style={{ color: "#6c757d" }}>Year of Joining </p>
              <Select
                value={data.yojval}
                onChange={this.handleyojChange}
                options={yoj}
                required
                placeholder={"Select..."}
              />
              {errors.yoj && <OnLineErrors text={errors.yoj} />}
            </div>

            <div style={{ marginTop: "1.5rem", width: "20rem" }}>
              <p style={{ color: "#6c757d" }}>UG / PG </p>
              <Select
                value={data.ug_pg}
                onChange={this.handleAdmissionChange}
                options={admission}
                placeholder={"Select..."}
              />
              {errors.ug_pg && (
                <OnLineErrors text={errors.ug_pg} />
              )}
            </div>


            <div style={{ marginTop: "1.5rem", width: "20rem" }}>
              <p style={{ color: "#6c757d" }}>Course </p>
              <Select
                value={data.courseval}
                onChange={this.handleCourseChange}
                options={this.state.data.selectcourse}
                //options={this.state.ugcourse}
                placeholder={"Select..."}
              />
              {errors.course && <OnLineErrors text={errors.course} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Speciality"
                label="Speciality"
                onChange={this.onChange}
                value={data.speciality}
                name="speciality"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.speciality && <OnLineErrors text={errors.speciality} />}
            </div>
            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Currently Working at"
                label="Currently Working at"
                onChange={this.onChange}
                value={data.job}
                name="job"
                autoFocus={true}
                margin="normal"
                style={{ width: 300 }}
              />
              <br />
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Name"
                label="Name"
                onChange={this.onChange}
                value={data.name}
                name="name"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.name && <OnLineErrors text={errors.name} />}
            </div>



            <div style={{ marginTop: "1.5rem" }}>
              <p style={{ color: "#6c757d" }}>Date Of Birth</p>
              <DatePicker
                selected={this.state.data.startDate}
                onChange={this.handleOnChangeDate}
                dateFormat="dd-MM-yyyy"
                placeholderText="dd-mm-yyyy"
                monthsShown={1}
                showYearDropdown
              />
              <br />
              {errors.dob && <OnLineErrors text={errors.dob} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Email"
                label="Email"
                onChange={this.onChange}
                value={data.email}
                name="email"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.email && <OnLineErrors text={errors.email} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Mobile Number"
                label="Mobile Number"
                onChange={this.handleOnChangeNum}
                value={data.phone}
                name="phone"
                autoFocus={true}
                required
                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.phone && <OnLineErrors text={errors.phone} />}
            </div>

            <div style={{ marginTop: "1.5rem" }}>
              <RadioComponent
                onGender={(val) => {
                  this.setState({
                    data: { ...this.state.data, gender: val },
                  });
                }}
              />
              <br />
              {errors.gender && <OnLineErrors text={errors.gender} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Hobby"
                label="Hobby"
                onChange={this.onChange}
                value={data.hobby}
                name="hobby"
                autoFocus={true}
                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.hobby && <OnLineErrors text={errors.hobby} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Spouse"
                label="Spouse"
                onChange={this.onChange}
                value={data.spouse}
                name="spouse"
                autoFocus={true}
                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.spouse && <OnLineErrors text={errors.spouse} />}
            </div>
            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Spouse Occupation"
                label="Spouse Occupation"
                onChange={this.onChange}
                value={data.spouseOccupation}
                name="spouseOccupation"
                autoFocus={true}
                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.spouseOccupation && <OnLineErrors text={errors.spouseOccupation} />}
            </div>

            <div style={{ marginTop: "2rem" }}>
              <p style={{ color: "#6c757d", fontWeight: '800' }}>Communication :</p>
            </div>



            <div style={{ marginTop: "0rem" }}>
              <TextField
                placeholder="Door No, Street"
                label="Door No, Street"
                onChange={this.onChange}
                value={data.address_line1}
                name="address_line1"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.address_line1 && (
                <OnLineErrors text={errors.address_line1} />
              )}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Area, Village"
                label="Area, Village"
                onChange={this.onChange}
                value={data.address_line2}
                name="address_line2"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.address_line2 && (
                <OnLineErrors text={errors.address_line2} />
              )}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Pincode"
                label="Pincode"
                onChange={this.handleOnChangeNum}
                value={data.pincode}
                name="pincode"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.pincode && <OnLineErrors text={errors.pincode} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="District"
                label="District"
                onChange={this.onChange}
                value={data.district}
                name="district"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.district && <OnLineErrors text={errors.district} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="State"
                label="State"
                onChange={this.onChange}
                value={data.state}
                name="state"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.state && <OnLineErrors text={errors.state} />}
            </div>

            <div style={{ marginTop: "0.2rem" }}>
              <TextField
                placeholder="Country"
                label="Country"
                onChange={this.onChange}
                value={data.country}
                name="country"
                autoFocus={true}

                margin="normal"
                style={{ width: 300 }}
              />
              <br />
              {errors.country && <OnLineErrors text={errors.country} />}
            </div>





            <div style={{ width: "50%", marginTop: "5rem" }}>
              {!this.state.loading ? (
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={this.onSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Loader
                  type="Circles"
                  color="#00BFFF"
                  height={50}
                  width={100}
                  timeout={10000}
                />
              )}
            </div>

            <div style={{ marginTop: "5rem" }}></div>
          </div>

          <div className="col-md-3"></div>
        </div>
      </div>
    );
  }
}

export default AppForm;
