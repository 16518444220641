import React, { Component } from "react";
import { TextField, Button } from "@material-ui/core";
import OnLineErrors from "./OnLineErrors";
import axios from "axios";
import { apiUrl } from "../config";
import Select from "react-select";
import { Spinner } from "react-activity";
import moment from "moment";
import Header from "./Header";
import viewicon from '../img/view.png';
import delicon from '../img/del.png';
class AppForm_Dash extends Component {
  state = {
    data: {
      isEdit: false,
      id: "",
      name: "",
      hobby:"",
      spouse:"",
      spouseOccupation:"",
      startDate: "",
      dob: "",
      job:"",      
      address_line1: "",
      address_line2: "",
      pincode: "",
      district: "",
      state: "",
      country: "",
      email: "",
      ug_pg: "",
      ug_pg_val: "",
      speciality: "",
      course: "",
      courseval: "",
      message: "",
      file1: "",
      photo: "",
      selectedOption: null,
      selectcourse: [],
      gender: "",
      yog: "",
      phone: "",
      regno: "",
      trigger: "#myModal",
    },
    file: null,
    mydata: [],
    errors: {},
    loading: false,
   
  };

  componentDidMount() {
    this.handleData();
  }

  handleData = () => {
    const id = this.state.tid;
    axios.get(`${apiUrl}/regform`).then((res) => {
      console.log("---- RES ----", res.data);
      this.setState({ mydata: res.data.result });
    });
  };

  handleModal = (e) => {
    //console.log(e);
    this.setState({
      data: { ...this.state.data,
      id: e.id,
      name:e.name,
      regno:e.regno,
      yoj:e.yoj,
      course:e.course,
      speciality:e.speciality,
      dob:e.dob,
      job:e.job,
      email:e.email,
      phone:e.phone,
      gender:e.gender,
      hobby:e.hobby,
      spouse:e.spouse,
      spouseOccupation:e.spouseOccupation,     
      address_line1:e.address_line1,
      address_line2:e.address_line2,
      pincode:e.pincode,
      district:e.district,
      city:e.city,
      state:e.state,
      country:e.country,
      trigger: "#myModal",
      isModalOpen: false
         },
    });
    
  };

  handleClear = () => {
    this.setState({
      file: null,
      data: {
        ...this.state.data,
        name: "",
        id: "",
        designation: "",
        department: "",
        file1: "",
      },
    });
  };

  onChange = (e) =>
    this.setState({
      data: { ...this.state.data, [e.target.name]: e.target.value },
    });

  onSubmit = () => {
    const { data } = this.state;
    const errors = this.validate(this.state.data);
    this.setState({ errors });
    if (Object.keys(errors).length === 0) {
      if (!data.isEdit) {
        //
        const formData = new FormData();
        formData.append("name", data.name);
        formData.append("designation", data.designation);
        formData.append("department", data.department.value);
        formData.append("myImage", data.file1);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        this.setState({ loading: true });
        axios
          .post(`${apiUrl}/staff/add`, formData, config)
          .then((response) => {
            this.setState({ loading: false });
            alert("The file is successfully uploaded");
            this.handleClear();
            this.handleData();
          })
          .catch((error) => {});
        //
      } else {
        const formData = new FormData();
        formData.append("id", data.id);
        formData.append("name", data.name);
        formData.append("designation", data.designation);
        formData.append("department", data.department);
        formData.append("myImage", data.file1);
        // console.log('--- FORMDATA ---',formData);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        this.setState({ loading: true });
        axios
          .post(`${apiUrl}/staff/update/${data.photo}`, formData, config)
          .then((response) => {
            this.setState({ loading: false });
            alert("The Details are successfully Updated");
            this.handleClear();
            this.handleData();
          })
          .catch((error) => {});
        //
      }
    }
  };

  onChangeFile = (event) => {
    //console.log('###',event.target.files[0])
    this.setState({
      file: URL.createObjectURL(event.target.files[0]),
      data: { ...this.state.data, file1: event.target.files[0] },
    });
  };
  resetFile = (event) => {
    event.preventDefault();
    this.setState({ file: null, data: { ...this.state.data, file1: "" } });
  };
  handleEdit = (e) => {
    //console.log('********** *******',e);
    this.handleClear();
    this.setState({
      data: {
        ...this.state.data,
        isEdit: true,
        id: e.id,
        photo: e.photo,
        name: e.name,
        designation: e.designation,
        department: e.department,
      },
    });
  };

  handleDelete = (e) => {
    var option = window.confirm(`Are You Sure want to delete ${e.name}`);
    if (option) {
      // const data = { fname: e.photo };
      //console.log('$$$$$ data $$$$$',e.id,data)
      axios.post(`${apiUrl}/regform/remove/${e.regno}`).then((res) => {
        this.handleData();
      }).then(()=>{
        window.location.reload(false)
      });
    }
  };

  handleDeleteFromModal = (id) => {
    var option = window.confirm(`Are You Sure want to Delete`);
    if (option) {
 
      axios.post(`${apiUrl}/regform/remove/${id}`).then((res) => {
        this.handleData();
        
      });
    }
  };

  handleName =()=>{
    window.print();
  }
  handleToggle() {
    this.setState({ data: {  ...this.state.data,  isModalOpen: !this.state.isModalOpen}
    });
  }

  validate = (data) => {
    const errors = {};
    if (!data.name) {
      errors.name = "Name Can't Empty";
    }
    if (!data.designation) {
      errors.designation = "Designation Can't Empty";
    }
    if (!data.department) {
      errors.department = "Department Can't Empty";
    }

    if (data.file1 === "" || data.file1 === undefined) {
      errors.file1 = "Image Can't Empty";
    }

    return errors;
  };

  render() {
    const { data, errors, loading } = this.state;

    //console.log("------- -------- ------", this.state.mydata);
    return (
      <div className="container-fluid">
        <Header onName={this.handleName}/>
    
        <div className="row" style={{ marginTop: "3rem" }}>
          <div className="col-md-0"></div>
          <div className="col-md-12">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr style={{ backgroundColor: "#27ae60	", color: "#fff" }}>
                  <th>S.NO</th>
                  <th>Reg No</th>
                  <th>Year Of<br></br> Joining</th>  
                  <th>Name</th>
                  <th>Course</th>
                  <th>Speciality</th>
                  <th>DOB</th>
                  <th>Mobile</th>
                  <th>Gender</th>
                  <th></th>
                  <th></th>
                  {/* <th>Spouse</th> */}
                  {/* <th>
                    Parent<br></br> Name
                  </th>
                  <th>Address</th> */}
                  
                </tr>
              </thead>

              <tbody>
                {this.state.mydata.length > 0 ? (
                  this.state.mydata.map((e, index) => (
                    <tr key={e.id} onClick={() => this.handleModal(e)} data-toggle="modal" data-target={this.state.data.trigger}>
                      <td>{index + 1}</td>
                      <td>{e.regno}</td>
                      <td>{e.yoj}</td>
                      <td>{e.name}</td>
                      <td>{e.course}</td>
                      <td>{e.speciality}</td>
                      <td>{moment(e.dob).format("DD-MM-YYYY")}</td>                     
                      <td>{e.phone}</td>
                      <td>{e.gender}</td>
                      <td>
                      {/* <button
                        type="button"
                        className="btn btn-warning"
                        data-toggle="modal"
                        data-target={this.state.data.trigger}
                        onClick={() => this.handleModal(e)}
                      >
                        Edit
                      </button> */}
                      {/* <img type="button" src={viewicon} onClick={() => this.handleModal(e)}  data-toggle="modal"
                        data-target={this.state.data.trigger} style={{height:'1.2rem',textAlign:'center', marginTop: "0rem"}} />
                    */}
                    </td>
                    <td>
                      {/* <button
                        type="button"
                        className="btn btn-warning"
                        data-toggle="modal"
                        data-target={this.state.trigger}
                        onClick={() => this.handleDelete(e)}
                      >
                        Remove
                      </button> */}
                      {/* <img type="button" src={delicon} onClick={() => this.handleDelete(e)}
                       style={{height:'1.0rem',textAlign:'center', marginTop: "0rem"}} /> */}
                    </td>
                      {/* <td>{e.spouse}</td> */}
                                        
                      {/* <td>
                        {e.address_line1}
                        <br></br>
                        {e.address_line2},<br></br>
                        {e.pincode},<br></br>
                        {e.district},<br></br>
                      </td> */}
                     
                    </tr>

                  
                  ))
                ) : (
                  <tr>
                    <td>No Data</td>
                  </tr>
                )}
              </tbody>
             
            </table>
            </div>
             {/*  */}
        <div>
          <div className="modal" id="myModal">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header" style={{backgroundColor:'#2980b9',color:'#fff'}}>
                  <h4 className="modal-title" >Details of {this.state.data.name} </h4>
                  <button type="button" className="close" data-dismiss="modal">
                    &times;
                  </button>
                </div>

                <div className="modal-body">

                  <div  className="row">
                  <div className="col-md-1">                      
                    </div>
                    <div className="col-md-5">
                      
                      <TextField
                        placeholder="Regd.No"
                        label="Regd.No"                       
                        value={this.state.data.regno || ''}
                        name="regno"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />

                      <TextField
                        placeholder="Year of Joining"
                        label="Year of Joining"                       
                       value={this.state.data.yoj || ''}
                        name="yoj"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />

                      <TextField
                        placeholder="Course"
                        label="Course"                       
                        value={this.state.data.course || ''}
                        name="course"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />

                      <TextField
                        placeholder="Speciality"
                        label="Speciality"                      
                        value={this.state.data.speciality || ''}
                        name="speciality"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />
                      <TextField
                        placeholder="Currently Working At"
                        label="Currently Working At"                      
                        value={this.state.data.job || ''}
                        name="job"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />
                      <TextField
                        placeholder="Name"
                        label="Name"                       
                        value={this.state.data.name || ''}
                        name="name"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                       <TextField
                        placeholder="DOB"
                        label="DOB"                       
                        value={this.state.data.dob || ''}
                        name="dob"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      /> 
                      <TextField
                        placeholder="Gender"
                        label="Gender"                      
                        value={this.state.data.gender || ''}
                        name="gender"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />

                      <TextField
                        placeholder="Spouse"
                        label="Spouse"                      
                        value={this.state.data.spouse || ''}
                        name="spouse"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />
                      <TextField
                        placeholder="spouse Occupation"
                        label="spouse Occupation"                      
                        value={this.state.data.spouseOccupation || ''}
                        name="spouseOccupation"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />
                    </div>
                    <div className="col-md-5">    
                 
                      <TextField
                        placeholder="Email"
                        label="Email"                       
                        value={this.state.data.email || ''}
                        name="email"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      /> 
                      <TextField
                        placeholder="Mobile"
                        label="Mobile"                       
                        value={this.state.data.phone || ''}
                        name="phone"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                       <TextField
                        placeholder="Door No, Street"
                        label="Door No, Street"                       
                        value={this.state.data.address_line1 || ''}
                        name="address_line1"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                       <TextField
                        placeholder="Area, Village"
                        label="Area, Village"                       
                        value={this.state.data.address_line2 || ''}
                        name="address_line2"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                      <TextField
                        placeholder="Pincode"
                        label="Pincode"                       
                        value={this.state.data.pincode || ''}
                        name="pincode"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                      <TextField
                        placeholder="District"
                        label="District"                       
                        value={this.state.data.district || ''}
                        name="district"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />   
                      <TextField
                        placeholder="State"
                        label="State"                       
                        value={this.state.data.state || ''}
                        name="state"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />   
                      <TextField
                        placeholder="Country"
                        label="Country"                       
                        value={this.state.data.country || ''}
                        name="country"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />   
                        <TextField
                        placeholder="Hobby"
                        label="Hobby"                       
                        value={this.state.data.hobby || ''}
                        name="hobby"
                        autoFocus={true}                        
                        margin="normal"
                        style={{ width: 300 }}
                      />    
                    </div>
                    <div className="col-md-1">                      
                    </div>
                    
                  </div>

                </div>

                <div className="modal-footer">
                    <button
                        type="button"
                        className="btn btn-danger"                       
                        // onClick={() => this.handleDeleteFromModal(this.state.data.regno) }
                        onClick={() => this.handleDeleteFromModal(this.state.data.id)} data-toggle="modal" data-target={this.state.data.trigger}
                      >
                        Remove Alumni
                      </button>
                    
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
          </div>
          <div className="col-md-0"></div>
        </div>
      </div>
    );
  }
}

export default AppForm_Dash;
