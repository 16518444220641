import React, { Component } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

class RadioComponent extends Component {
  state = {
    gender: "",
  };
  onChange = (e) =>
    this.setState(
      {
        gender: e.target.value,
      },
      () => {
        this.props.onGender(this.state.gender);
      }
    );

  render() {
   
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Gender</FormLabel>
        <RadioGroup row aria-label="position" name="position">
          <FormControlLabel
            value="male"
            control={<Radio color="primary" />}
            label="Male"
            onChange={(e) => {
              this.onChange(e);
            }}
          />

          <FormControlLabel
            value="female"
            control={<Radio color="primary" />}
            label="Female"
            onChange={(e) => {
              this.onChange(e);
            }}
          />
        </RadioGroup>
      </FormControl>
    );
  }
}

export default RadioComponent;
