import React, { Component } from 'react';
import {TextField, Button} from "@material-ui/core";
import OnLineErrors from './OnLineErrors';
import axios from 'axios';
import { apiUrl } from "../config"
import { Spinner } from 'react-activity';
import 'react-activity/dist/react-activity.css';


class Enquiry extends Component {
    state = {
        data: {
          name:'',
          phone:'',
          message:''
        },        
        errors: {},
        loading:false
      };

      onChange = e =>  this.setState (
        { data: { ...this.state.data, [e.target.name]: e.target.value} }
      );
      onChangeNo = e => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value))  {
          if (e.target.value.length !== 11) {
            this.setState({ data: {...this.state.data, [e.target.name]: e.target.value}})
          }
        }
     };

     handleClear = ()=>{
        this.setState({data: { ...this.state.data,name:'',phone:'',message:''}})          
    }

      onSubmit = () => {         
        const { data } = this.state;
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {            
            alert("Thank You, Registered successfully");
            this.handleClear();             
            //this.setState({loading:true})
            axios
            .post(`${apiUrl}/staff/mail`, data)
            .then(response => {
            // this.setState({loading:false})
            //   alert("Thank You, Registered successfully");
            //   this.handleClear();             
            })
            .catch(error => { });
          }
      };

      validate = (data) => {
        const errors={};
        if (!data.name) {
          errors.name = "Name Can't Empty"
        }
        if (!data.phone) {
          errors.phone = "MobileNumber Can't Empty"
        }
        if (!data.message) {
            errors.message = "Message Can't Empty"
          }
       
        return errors;
      };


    render() {
        const { data, errors, loading } = this.state;      
        return (
            <div className="container-fluid">
                <div className="row" style={{marginTop:'2rem'}}>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    <h2 style={{marginTop:'3rem',textAlign:'center'}}> Enquiry Form</h2> 
                    </div>
                    <div className="col-md-4">
                    </div>

                </div>

                
                <div className="row" style={{marginTop:'2rem'}}>
                    <div className="col-md-4">
                    </div>
                    <div className="col-md-4">
                    <div  style={{boxShadow: '0 0 19.3px 15.8px rgba(0, 0, 0, 0.05)',padding:'1rem'}}>
                                         
                            <TextField         
                            placeholder="Enter the Name"
                            label="Name"
                            onChange={this.onChange}
                            value={data.name}                
                            name="name"
                            autoFocus={true}
                            required
                            margin="normal"                 
                            fullWidth={true} />
                            {errors.name && <OnLineErrors text={errors.name} />} 

                            <TextField
                                id="phone"
                                placeholder="Primery Mobile No Only"
                                label="Mobile Number"
                                autoComplete="phone"
                                name="phone"
                                required
                                onChange={this.onChangeNo}
                                value={data.phone}
                                margin="normal"
                                fullWidth={true} />
                                {errors.phone && <OnLineErrors text={errors.phone} />}


                           <TextField                
                            placeholder="Enter the Message"
                            label="Message"
                            onChange={this.onChange}
                            value={data.message}                
                            name="message"
                            autoFocus={true}
                            required
                            margin="normal"                 
                            fullWidth={true}                            
                            multiline
                            rowsMax={4}
                          />
                           {errors.message && <OnLineErrors text={errors.message} />} 

                          <div style={{marginTop:'2rem'}}>
                            <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSubmit}>
                                    Submit
                                </Button>    
                          </div>
                          <div style={{marginTop:'3rem'}}>
                              {
                                  this.state.loading ?
                                  <div style={{marginLeft:'40%'}}>
                                       <Spinner  color='red' size={20}/> 
                                  </div>                                 
                                  : null
                              }
                             
                          </div>
                         
                            
                    </div>
                    </div>
                    <div className="col-md-4">
                    </div>
                </div>
               
            </div>
        );
    }
}

export default Enquiry;