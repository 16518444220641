import React, { Component } from 'react';
import {TextField, Button} from "@material-ui/core";
import OnLineErrors from './OnLineErrors';
import axios from 'axios';
import { apiUrl } from "../config"
import Select from 'react-select';
import { Spinner } from 'react-activity';
import Header from './Header';

class Faculty_Reg extends Component {
    state = {
        data: {
          isEdit:false,  
          id:'',
          name:'',
          designation:'',
          department:'',         
          file1:'',
          photo:'' ,
          selectedOption: null,
        },
        file: null,
        mydata: [],
        errors: {},
        loading:false
      };

      componentDidMount() {   
        this.handleData();
      }
 
      handleData = ()=>{
         const id=this.state.tid;
         axios.get(`${apiUrl}/staff`).then((res) => {
            // console.log("---- RES ----", res.data);
             this.setState({ mydata: res.data.result });
            });
      }

      handleClear = ()=>{
          this.setState({file:null,data: { ...this.state.data,name:'',id:'',designation:'',department:'',file1:''}})          
      }

      onChange = e =>  this.setState (
        { data: { ...this.state.data, [e.target.name]: e.target.value} }
      );

      handleDepChange = department => {
        this.setState( { data: { ...this.state.data, department:department} });
        console.log(`Option selected:`, department);
      };

      onSubmit = () => {
        const { data } = this.state;
        const errors = this.validate(this.state.data);
        this.setState({errors});
        if (Object.keys(errors).length === 0) {            
             if(!data.isEdit){
            // 
            const formData = new FormData();
            formData.append("name", data.name);
            formData.append("designation", data.designation);
            formData.append("department", data.department.value);
            formData.append("myImage", data.file1);         
            const config = {
              headers: {
                "content-type": "multipart/form-data"
              }
            };
            this.setState({loading:true})
            axios
              .post(`${apiUrl}/staff/add`, formData, config)
              .then(response => {
                this.setState({loading:false})
                alert("The file is successfully uploaded");
                this.handleClear();
                this.handleData();
              })
              .catch(error => { });
            //
             } else {
                const formData = new FormData();
                formData.append("id", data.id);
                formData.append("name", data.name);
                formData.append("designation", data.designation);
                formData.append("department", data.department);
                formData.append("myImage", data.file1);   
              console.log('--- FORMDATA ---',formData);
                const config = {
                  headers: {
                    "content-type": "multipart/form-data"
                  }
                };
                this.setState({loading:true})
                axios
                  .post(`${apiUrl}/staff/update/${data.photo}`, formData, config)
                  .then(response => {
                    this.setState({loading:false})
                    alert("The Details are successfully Updated");
                    this.handleClear();
                    this.handleData();
                  })
                  .catch(error => { });
                //
             }

          }
      };

      onChangeFile=(event)=> {   
          //console.log('###',event.target.files[0])    
        this.setState({
          file: URL.createObjectURL(event.target.files[0]), data: { ...this.state.data, file1: event.target.files[0]}
        });
      }    
      resetFile=(event)=> {
        event.preventDefault();       
        this.setState({file: null, data: { ...this.state.data,file1:''} })      
      }
      handleEdit = (e)=>{        
            //console.log('********** *******',e);
            this.handleClear();
            this.setState({
            data:{...this.state.data,isEdit:true,id:e.id,photo:e.photo, name:e.name,designation:e.designation,department:e.department}             
            });        
      }
      handleDelete = (e) => {         
        var option = window.confirm(`Are You Sure want to delete ${e.name}`);
        if (option) {
           const data = {fname:e.photo}
           //console.log('$$$$$ data $$$$$',e.id,data)
           axios.post(`${apiUrl}/staff/delete/${e.id}`,data).then((res) => {
           this.handleData();
           });
        }
      };

      validate = (data) => {
        const errors={};
        if (!data.name) {
          errors.name = "Name Can't Empty"
        }
        if (!data.designation) {
          errors.designation = "Designation Can't Empty"
        }
        if (!data.department) {
            errors.department = "Department Can't Empty"
          }

          if(data.file1 === '' || data.file1 === undefined ){
            errors.file1 = "Image Can't Empty"
           }
               
        return errors;
      };


    render() {
    
       const { data, errors, loading } = this.state;      
       const options = [
        { value: 'Tamil', label: 'Tamil' },
        { value: 'English', label: 'English' },
        { value: 'Computer Science', label: 'Computer Science' },
        { value: 'Mathematics ', label: 'Mathematics' },
        { value: 'Physics ', label: 'Physics' },
      ];
      //console.log('------- -------- ------',data.department)
        return (
            <div className="container-fluid">
                <Header />
                <div className="row">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6">
                      <div className="row" style={{marginTop:'3rem'}}>
                          <div className="col-md-3">
                          </div>
                          <div className="col-md-6">
                          <h2 > Staff Registration</h2>   
                          </div>
                          <div className="col-md-3">
                          <Button
                                    type="submit"
                                   
                                    variant="contained"
                                    color="secondary"
                                    onClick={()=>{localStorage.clear()
                                      this.props.history.push('/')}}>
                                    Log Out 
                                </Button>  
                          </div>
                      </div>
                                        
                            <TextField error={!!errors.name}                
                            placeholder="Enter the Name"
                            label="Name"
                            onChange={this.onChange}
                            value={data.name}                
                            name="name"
                            autoFocus={true}
                            required
                            margin="normal"                 
                            fullWidth={true} />
                            {errors.name && <OnLineErrors text={errors.name} />} 

                            <TextField error={!!errors.designation}                
                            placeholder="Enter the Designation"
                            label="Designation"
                            onChange={this.onChange}
                            value={data.designation}                
                            name="designation"
                            autoFocus={true}
                            required
                            margin="normal"                 
                            fullWidth={true} />
                           {errors.designation && <OnLineErrors text={errors.designation} />} 

                            <div style={{marginTop:'1.5rem'}}>
                                <Select
                                    //  value=  {data.isEdit ? options.filter(function(option) {
                                    //     return option.value === data.department;
                                    //   }): data.department}
                                    value={data.department}
                                    onChange={this.handleDepChange}
                                    options={options}
                                    placeholder={data.isEdit ? data.department : 'Select...'}
                                />   
                            </div>                        
                           {errors.department && <OnLineErrors text={errors.department} />} 
                           
                            <div style={{marginTop:'2rem'}}>
                                {!this.state.file && (
                                <input type="file" onChange={this.onChangeFile} />                                
                                    )}                                    
                                {this.state.file && (
                                    <div style={{ textAlign: "center" }}>  
                                    <img className="img-border" src={this.state.file} style={{height:'5rem'}} />  
                                        <div>
                                        <button className="btn btn-danger" onClick={this.resetFile}>remove</button>
                                        </div>
                                    </div>
                                    )}
                                    <br></br>
                                {errors.file1 && <OnLineErrors text={errors.file1} />} 
                                              
                            </div>

                          <div style={{marginTop:'2rem'}}>
                            <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={this.onSubmit}>
                                    Register
                                </Button>    
                          </div>
                          <div style={{marginTop:'2rem'}}>
                              {
                                  this.state.loading ?
                                  <div style={{marginLeft:'40%'}}>
                                       <Spinner  color='red' size={20}/> 
                                  </div>                                 
                                  : null
                              }
                             
                          </div>
                            
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>
               
               <div className="row" style={{marginTop:'3rem'}}>
                
                        <div className="col-md-0"></div>
                        <div className="col-md-12">

                        <table className="table">
                      <thead>
              <tr>
                <th>S.NO</th>
                <th>Name</th>
                <th>Photo</th>
                <th>Designation</th>
                <th>Department</th>                   
              </tr>
            </thead>
          
                      <tbody>
              {this.state.mydata.length > 0 ? (
                this.state.mydata.map((e,index) => (
                  <tr key={e.id}>
                    <td>{index + 1}</td>
                    <td>{e.name}</td>
                    <td>
                    <img src={`${apiUrl}/uploads/${e.photo}`} style={{height:'2rem'}} />                                    
                    </td>
                    <td>{e.designation}</td>
                    <td>{e.department}</td>                  
                    <td>
                      <button
                        type="button"  
                        className="btn btn-warning"                     
                        onClick={() => this.handleEdit(e)}
                      >
                        Edit
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-danger"
                        onClick={() => {
                          this.handleDelete(e);
                        }}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                  
                ))
                
              ) : (
                <tr>
                  <td>No Data</td>
                </tr>
              )}
             
            </tbody>
                      </table>                       
             

                        </div>
                        <div className="col-md-0"></div>
                   </div>
               
            </div>
        );
    }
}

export default Faculty_Reg;